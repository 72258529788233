<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Mes abonnements</v-card-title>
        <v-card-subtitle>Gérez vos abonnements</v-card-subtitle>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-title>Recevoir des e-mails d'informations liées à mes abonnements</v-list-item-title>
                    <v-switch :input-value="subscriptions.newsletters" @change="() => subscriptions.newsletters = !subscriptions.newsletters" class="float-right" switch size="lg"/>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-for="(sub, key) in subscriptions.item_sub" :key="key" class="v-list-item-custom">
                    <v-list-item-title>{{ sub.nom_abonnement }}</v-list-item-title>
                    <v-btn icon class="ml-2">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Subscriptions',
    data() {
        return {
            subscriptions : JSON.parse(localStorage.getItem('settings')).main.subscriptions,
            subscriptions_backup : JSON.parse(localStorage.getItem('settings')).main.subscriptions,
        }
    },
    methods : {
        saveSettings() {
            if (this.subscriptions_backup !== this.subscriptions) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.main.subscriptions = this.subscriptions;
                this.subscriptions_backup = JSON.parse(JSON.stringify(this.subscriptions));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.subscriptions = JSON.parse(JSON.stringify(this.subscriptions_backup));
        },
        isChanges () {
            if (JSON.stringify(this.subscriptions_backup) !== JSON.stringify(this.subscriptions)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .v-list-item-custom {
        margin-bottom: 5px;
        background-color:var(--v-bg_card_app-darken1);
    }
</style>